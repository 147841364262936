import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../Loader";
import API_BASE_URL from "../utils";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    sendMe: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${API_BASE_URL}getInTouch`, formData);
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gradient-to-r from-[#131c44] to-[#1a2c66] p-6">
      <Helmet>
        <title>Contact Andtelligence | Aerospace & Defence AI Solutions</title>
        <meta
          name="description"
          content="Get in touch with Andtelligence for AI-powered solutions in Aerospace & Defence. Contact us today to streamline operations and drive industry innovation."
        />
      </Helmet>

      {/* Background Image Section */}
      {loading && <Loader />}
      <div
        className="absolute top-0 left-0 w-full h-[40%] bg-cover bg-center opacity-40"
        style={{
          backgroundImage: "url('/contact-bg.jpg')",
        }}
      />

      {/* Overlay Section */}
      <div className="relative w-full max-w-4xl bg-black bg-opacity-60 rounded-lg shadow-lg p-6 md:p-12 flex flex-col md:flex-row">
        {/* Form Section */}
        <div className="flex-1 mb-8 lg:w-[70%] w-full md:mr-4">
          <h2 className="text-2xl font-semibold text-white mb-6 justify-center flex lg:justify-start">
            Get in Touch
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-3 rounded bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email address"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-3 rounded bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <textarea
              name="message"
              placeholder="Message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 rounded bg-gray-800 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <div className="flex items-center">
              <input
                type="checkbox"
                id="copy"
                name="sendMe"
                checked={formData.sendMe}
                onChange={handleChange}
                className="text-blue-500 rounded focus:ring-blue-500"
              />
              <label htmlFor="copy" className="ml-2 text-gray-400 text-sm">
                Send me a copy of this message
              </label>
            </div>
            <div className="flex justify-center items-center lg:justify-start">
              <button
                type="submit"
                className={`text-sm text-black bg-gray-300 font-medium rounded-lg px-4 py-2.5 duration-300 transition-colors border hover:bg-green ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={loading}
              >
                {loading ? "Sending..." : "SEND"}
              </button>
            </div>
          </form>
        </div>

        {/* Contact Info Section */}
        <div className="lg:w-[30%] w-full flex justify-center items-center text-center">
          <p className="text-white text-sm lg:text-base">
            We're here to help! Feel free to reach out for any type of query.
            Whether it's a technical issue, a bug report, a suggestion, or just
            a question. Our team is ready to assist you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
