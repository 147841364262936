import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  useMediaQuery,
} from "@mui/material";
import ShadowRoot from "react-shadow-root";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import API_BASE_URL from "../utils";
import Loader from "../Loader";

interface Subsection {
  subsection?: string;
  categories?: string[];
}

interface Section {
  section: string;
  subsections?: (string | Subsection)[];
}

interface TOCData {
  sections: (string | Section)[];
}

interface RelatedDocument {
  query_name: string;
  document_id: number;
  heading_2: string;
}

const DetailedArticle: React.FC = () => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)");
  const { filename, document_id } = useParams<{
    filename: string;
    document_id: string;
  }>();

  const [htmlContent, setHtmlContent] = useState<string | null>(null);
  const [documentData, setDocumentData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [relatedTopics, setRelatedTopics] = useState<RelatedDocument[]>([]);
  const [isSummary, setSummary] = useState(true);
  const [open, setOpen] = useState(false);
  // const [isOpenSections, setIsOpenSections] = useState({});
  const [isOpenSections, setIsOpenSections] = useState<{
    [key: number]: boolean;
  }>({});
  const [modalContent, setModalContent] = useState({
    title: "",
    description: "",
  });
  const [formData, setFormData] = useState({
    clientName: "",
    company: "",
    jobTitle: "",
    email: "",
    phoneNumber: "",
    query: "",
  });

  const fetchRelatedTopics = async (document_id: any) => {
    try {
      const response = await axios.get(`${API_BASE_URL}getrelatedTopics`, {
        params: {
          document_id: document_id,
        },
      });

      setRelatedTopics(response?.data?.data);
      return;
    } catch (error) {
      console.error("Error fetching related data:", error);
      throw new Error("Failed to fetch related data");
    }
  };

  const fetchDocumentData = async (filename: any, document_id: any | any[]) => {
    try {
      const response = await axios.get(`${API_BASE_URL}getDocumentById`, {
        params: {
          query_name: filename,
          document_id: document_id,
        },
      });
      return response?.data?.data;
    } catch (error) {
      console.error("Error fetching document data:", error);
      throw new Error("Failed to fetch document data");
    }
  };

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleOpen = (title: string, description: string) => {
    setModalContent({ title, description });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (filename && document_id) {
        try {
          const data = await fetchDocumentData(filename, document_id);
          setDocumentData(data);

          if (data?.document_link) {
            const response = await axios.get(data.document_link, {
              responseType: "text",
            });
            setHtmlContent(response.data);
          } else {
            throw new Error("Document link not found");
          }
        } catch (error) {
          setError("Something went wrong");
        } finally {
          setLoading(false);
        }
      }
    };

    getData();
    fetchRelatedTopics(document_id);
  }, [filename, document_id]);

  const toggleOpen = (index: number) => {
    setIsOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const sendEmail = async (button: string) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}theEmailSender`, {
        button: button,
        clientName: formData.clientName,
        company: formData.company,
        jobTitle: formData.jobTitle,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        reportName: documentData?.sub_category,
        reportSegment: documentData?.heading_1,
        inquiryText: formData.query,
      });

      // Handle response if needed
      handleClose();
      setLoading(false);
    } catch (error) {
      console.error("Error sending email:", error);
      handleClose();
      setLoading(false);
      throw error; // Re-throw the error for handling in the calling component
    }
  };

  const TOCCCC: TOCData = {
    sections: [
      {
        section: "Introduction",
        subsections: [
          "Market Segmentation",
          "Market Dashboard",
          "Research Methodology",
          "Definitions and Assumptions",
        ],
      },
      "Key Market Insights",
      "Key Industry Developments – Key Contracts & Agreements, Mergers, Acquisitions and Partnerships",
      "Latest technological Advancements",
      "End-user Analysis",
      "Value Chain Analysis",
      "Go To Market Strategies",
      {
        section: "Product/Service Case Studies",
        subsections: [
          "Top 3 Key Players",
          "Customer Reviews",
          "Change in Industry",
        ],
      },
      {
        section:
          "Global Aircraft Aftermarket Parts Market Analysis, Insights and Forecast 2019-2032",
        subsections: [
          "Key Findings",
          {
            subsection: "Market Analysis and Forecast – By Part Type",
            categories: ["OEM", "USM", "PMA", "Consumables"],
          },
          {
            subsection: "Market Analysis and Forecast – By Service Life",
            categories: ["Rotable Parts", "Life Limited Parts"],
          },
          {
            subsection: "Market Analysis and Forecast – By Application",
            categories: [
              "Commercial Aircraft",
              "Narrow Body",
              "Wide Body",
              "Regional Aircraft",
              "Freighters",
              "Helicopters",
              "Business Jet",
            ],
          },
          {
            subsection: "Market Analysis and Forecast – By Component",
            categories: [
              "Engine",
              "Avionics",
              "Interiors",
              "Control System",
              "Landing Gear",
              "Others",
            ],
          },
          {
            subsection: "Market Analysis and Forecast – By End-User",
            categories: ["MRO Service Providers", "Airlines"],
          },
          {
            subsection: "Market Analysis and Forecast – By Geography",
            categories: [
              "North America",
              "Europe",
              "Asia Pacific",
              "Rest of the World",
            ],
          },
        ],
      },
      {
        section:
          "North America Aircraft Aftermarket Parts Market Analysis, Insights and Forecast 2019-2032",
        subsections: [
          "Key Findings",
          "Market Analysis and Forecast – By Part Type",
          "Market Analysis and Forecast – By Service Life",
          "Market Analysis and Forecast – By Application",
          "Market Analysis and Forecast – By Component",
          "Market Analysis and Forecast – By End-User",
          "Market Analysis and Forecast – By Country",
        ],
      },
      {
        section:
          "Europe Aircraft Aftermarket Parts Market Analysis, Insights and Forecast 2019-2032",
        subsections: [
          "Key Findings",
          "Market Analysis and Forecast – By Part Type",
          "Market Analysis and Forecast – By Service Life",
          "Market Analysis and Forecast – By Application",
          "Market Analysis and Forecast – By Component",
          "Market Analysis and Forecast – By End-User",
          "Market Analysis and Forecast – By Country",
        ],
      },
      {
        section:
          "Asia Pacific Aircraft Aftermarket Parts Market Analysis, Insights and Forecast 2019-2032",
        subsections: [
          "Key Findings",
          "Market Analysis and Forecast – By Part Type",
          "Market Analysis and Forecast – By Service Life",
          "Market Analysis and Forecast – By Application",
          "Market Analysis and Forecast – By Component",
          "Market Analysis and Forecast – By End-User",
          "Market Analysis and Forecast – By Country",
        ],
      },
      {
        section:
          "Rest of the World Aircraft Aftermarket Parts Market Analysis, Insights and Forecast 2019-2032",
        subsections: [
          "Key Findings",
          "Market Analysis and Forecast – By Part Type",
          "Market Analysis and Forecast – By Service Life",
          "Market Analysis and Forecast – By Application",
          "Market Analysis and Forecast – By Component",
          "Market Analysis and Forecast – By End-User",
          "Market Analysis and Forecast – By Country",
        ],
      },
      {
        section: "Competitive Analysis",
        subsections: [
          "Global Market Share Analysis 2023, By Key Players",
          "Competitor Dashboard",
        ],
      },

      {
        section: "Company Profiles",
        subsections: [
          "AAR",
          "Eaton",
          "Parker Hannifin Corporation",
          "General Electric",
          "Honeywell International Inc.",
          "Meggitt PLC",
          "Collins Aerospace",
          "The Boeing Company",
          "Moog Inc.",
          "Safran Group",
        ],
      },
    ],
  };

  return (
    <>
      <div className="bg-white">
        {loading && <Loader />}
        <div className="">
          <div className="mx-auto max-w-7xl px-6">
            <div className="pt-4">
              <p className="text-sm lg:text-2xl">{documentData?.heading_1}</p>
              <p className="text-base font-medium lg:text-3xl pt-2 lg:pt-5">
                {documentData?.heading_2}
              </p>
              <p className="text-sm lg:text-lg pt-1">
                {documentData?.heading_3}
              </p>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-4 my-2 lg:my-4">
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Base Year
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.base_year}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Region
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.region}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Research Code
                </p>
                <p className="font-normal text-sm lg:text-base">
                  DOC-00{documentData?.document_id}
                </p>
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-sm lg:text-base text-navyblue">
                  Status
                </p>
                <p className="font-normal text-sm lg:text-base">
                  {documentData?.status}
                </p>
              </div>
            </div>
          </div>

          <div
            className="flex justify-center"
            style={{
              backgroundImage: `url('/images/abg.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              // height: "100vh",
            }}
          >
            <div className="max-w-7xl px-6 mt-4 w-full">
              <div className="border-b-[3px] border-black flex justify-between w-full lg:w-[75%] mb-4">
                <div className="mb-4 gap-4 justify-between flex w-full lg:justify-start">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: isSummary ? "#1A2B6D" : "#ccc",
                      marginRight: "50px",
                    }}
                    size={isLargeScreen ? "medium" : "small"}
                    onClick={() => setSummary(true)}
                  >
                    Summary
                  </Button>
                  {documentData?.document_type === "report" && (
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: !isSummary ? "#1A2B6D" : "#ccc",
                      }}
                      size={isLargeScreen ? "medium" : "small"}
                      onClick={() => setSummary(false)}
                    >
                      TOC
                    </Button>
                  )}
                </div>
                <div className="hidden lg:block">
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#1A2B6D",
                      whiteSpace: "nowrap", // Prevents text from wrapping
                      minWidth: "180px",
                    }}
                    size={isLargeScreen ? "medium" : "small"}
                    onClick={() =>
                      handleOpen(
                        "Request Sample",
                        "Our team will swiftly act on your request and we will send you the quote within 24 hours. If there are specific customisations required please click the Inquire Now button or Speak to our analyst."
                      )
                    }
                  >
                    Request Sample
                  </Button>
                </div>
              </div>

              <Grid container>
                <Grid item xs={12} md={9}>
                  <Box sx={{ width: "100%", overflow: "auto" }}>
                    {isSummary && (
                      <div className="h-[180vh] overflow-auto rounded-md border border-x-blue mb-4 p-4 bg-white">
                        {loading && <p>Loading...</p>}
                        {error && <p>{error}</p>}
                        {htmlContent && (
                          <ShadowRoot>
                            <div
                              className="htmlContent"
                              dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                            <style>
                              {`
  .htmlContent {
    font-family: Arial, sans-serif;
  }

  .htmlContent h1 {
    font-size: 2em; /* Ensure this is the size you want */
    font-weight: bold;
    text-decoration: underline;
  }

  .htmlContent h2,
  .htmlContent h3,
  .htmlContent p {
    margin: 0;
    padding: 0;
  }

  .htmlContent p {
    margin-bottom: 1em;
  }

  .htmlContent ul,
  .htmlContent ol {
    margin: 1em 0;
    padding-left: 1.5em;
  }

  .htmlContent ul {
    list-style-type: disc;
  }

  .htmlContent ol {
    list-style-type: decimal;
  }

  .htmlContent table {
    border-collapse: collapse;
    width: 100%;
  }

  .htmlContent th,
  .htmlContent td {
    border: 1px solid #000;
    padding: 8px;
  }
`}
                            </style>
                          </ShadowRoot>
                        )}
                      </div>
                    )}
                    {!isSummary && (
                      <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-white mt-4">
                        <div className="p-3">
                          <p className="font-semibold text-2xl text-[#1C77C3] mb-4">
                            TABLE OF CONTENT
                          </p>

                          {/* chat gpt */}

                          <div>
                            {TOCCCC.sections.map((section, index) => {
                              const hasSubsections =
                                typeof section !== "string" &&
                                section.subsections &&
                                section.subsections.length > 0;

                              return (
                                <div key={index} className="mb-2">
                                  {/* Main Section Header */}
                                  <div
                                    className={`font-normal border-b flex items-center ${
                                      hasSubsections ? "cursor-pointer" : ""
                                    }`}
                                    onClick={() =>
                                      hasSubsections && toggleOpen(index)
                                    }
                                  >
                                    {/* Icon */}
                                    {hasSubsections && (
                                      <div className="mr-2">
                                        {isOpenSections[index] ? (
                                          <ExpandMore
                                            style={{ fontSize: 25 }}
                                          />
                                        ) : (
                                          <ChevronRightIcon
                                            style={{ fontSize: 25 }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {/* Section Title */}
                                    <div className="flex justify-between items-center w-full">
                                      <p className="p-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base">
                                        {index + 1}.{" "}
                                        {typeof section === "string"
                                          ? section
                                          : section.section ||
                                            "Untitled Section"}
                                      </p>
                                    </div>
                                  </div>

                                  {/* Expandable Subsections */}
                                  {isOpenSections[index] &&
                                    hasSubsections &&
                                    typeof section !== "string" &&
                                    section.subsections && (
                                      <div className="ml-8 mt-2">
                                        {section.subsections.map(
                                          (subsection, subIndex) => (
                                            <div key={subIndex} className="p-2">
                                              {/* Subsection Title */}
                                              <p className="font-normal lg:font-medium border-b cursor-pointer text-xs lg:text-sm">
                                                {index + 1}.{subIndex + 1}{" "}
                                                {typeof subsection === "string"
                                                  ? subsection
                                                  : subsection.subsection ||
                                                    "Untitled Subsection"}
                                              </p>
                                              {/* Subsection Categories */}
                                              {typeof subsection !== "string" &&
                                                subsection.categories && (
                                                  <ul className="ml-4 list-disc">
                                                    {subsection.categories.map(
                                                      (category, catIndex) => (
                                                        <li
                                                          key={catIndex}
                                                          className="ont-normal lg:font-medium border-b cursor-pointer text-xs lg:text-sm"
                                                        >
                                                          {category}
                                                        </li>
                                                      )
                                                    )}
                                                  </ul>
                                                )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>

                          {/* chat gpt ended */}
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      marginLeft: isLargeScreen ? "10px" : "",
                    }}
                  >
                    <div className="flex flex-row justify-around lg:justify-between items-center w-full">
                      <div className="">
                        <img
                          src="/images/notepad.png"
                          alt="notpad graphic"
                          width={isLargeScreen ? 85 : 60}
                          height={isLargeScreen ? 85 : 60}
                        />
                      </div>
                      <div className="justify-center items-center flex flex-col ">
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Request for Quote",
                              "Our team will swiftly act on your request and we will send you the quote within 24 hours. If there are specific customisations required please click the Inquire Now button or Speak to our analyst."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none"
                        >
                          Get a quote
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Inquire Now",
                              "Thank you for your interest in our services. Please provide your details, and we will get in touch soon."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Inquire now
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handleOpen(
                              "Speak To An Analyst",
                              "Thank you for your interest in our services. Please provide your details, and we will get in touch soon."
                            )
                          }
                          className="text-white w-full bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-xs lg:text-sm px-2 lg:px-5 py-3 lg:me-2 mb-1 lg:mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                        >
                          Speak to an analyst
                        </button>
                      </div>
                    </div>
                    {/* disabled for articles */}
                    {/* 
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5] mb-4">
                      <div className="p-5">
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="singleUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Single User
                            </label>
                            <p className="font-bold text-lg">$4350</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="multiUser"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Multi User
                            </label>
                            <p className="font-bold text-lg">$5500</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center mb-4">
                          <div className="flex justify-between w-full">
                            <label className="font-normal text-lg flex items-center">
                              <input
                                type="radio"
                                name="pricing"
                                value="enterprise"
                                style={{
                                  width: "20px", // Increase size
                                  height: "20px", // Increase size
                                  accentColor: "#1A2B6D", // Set color for modern browsers
                                  cursor: "pointer",
                                }}
                                className="mr-2"
                              />
                              Enterprise
                            </label>
                            <p className="font-bold text-lg">$6500</p>
                          </div>
                        </div>
                        <div className="flex justify-center items-center">
                          <button
                            type="button"
                            className="text-white bg-blue-700 hover:bg-navyblue focus:ring-4
                      font-medium rounded-lg text-sm px-5 py-3 me-2 mb-2 bg-[#1A2B6D] focus:outline-none
                      "
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                    </div> */}
                    <div className="rounded overflow-hidden shadow-lg border border-offwhite bg-[#F5F5F5]">
                      <div className="p-5">
                        <p className="font-semibold text-base lg:text-lg text-[#1C77C3]">
                          RELATED TOPICS
                        </p>
                        {relatedTopics?.map(
                          (value: RelatedDocument, index: number) => (
                            <p
                              key={index}
                              className="mb-2 font-normal lg:font-medium border-b cursor-pointer text-sm lg:text-base"
                              onClick={() =>
                                (window.location.href = `/article/${value.query_name}/${value.document_id}`)
                              }
                            >
                              {value?.heading_2}
                            </p>
                          )
                        )}
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle className="lg:font-bold text-center font-medium text-sm">
          {modalContent.title}
        </DialogTitle>
        <DialogContent className="lg:space-y-4 space-y-1">
          <p className="text-xs lg:text-base text-center">
            {modalContent.description}
          </p>

          <div className="lg:space-y-4 space-y-1">
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Your Name"
              name="clientName"
              variant="outlined"
              value={formData.clientName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Company"
              name="company"
              variant="outlined"
              value={formData.company}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Job Title"
              name="jobTitle"
              variant="outlined"
              value={formData.jobTitle}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              size={isLargeScreen ? "medium" : "small"}
              label="Phone Number"
              name="phoneNumber"
              variant="outlined"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            {(modalContent.title === "Inquire Now" ||
              modalContent.title === "Speak To An Analyst") && (
              <TextField
                label="Your Query"
                name="query"
                size={isLargeScreen ? "medium" : "small"}
                value={formData.query}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} className="text-gray-600" size="small">
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            disabled={loading}
            onClick={() => {
              if (
                modalContent.title === "Inquire Now" ||
                modalContent.title === "Speak To An Analyst"
              ) {
                sendEmail("inquire");
              } else if (
                modalContent.title === "Request for Quote" ||
                modalContent.title === "Request Sample"
              ) {
                sendEmail("quote");
              }
            }}
            className="bg-blue-600 hover:bg-blue-700"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailedArticle;
