import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Banner from './components/Banner';
import Companies from './components/Companies';
import Work from './components/Work';
import Table from './components/Table';
import Features from './components/Features';
import Simple from './components/Simple';
import Trade from './components/Trade';
import Faq from './components/Faq';
import TestimonialSlider from './components/Testimonials/TestimonialSlider';
import CookieNotice from './components/CookieNotice';
import RootLayout from './components/layout';
import Categories from './components/Articles/Categories';
import DetailedArticle from './components/Articles/DetailedArticle';
import ContactForm from './components/Contact';
import AboutUs from './components/About';

// Wrapper Component for Redirect to a File
const RedirectToFile: React.FC<{ fileName: string }> = ({ fileName }) => {
  useEffect(() => {
    window.location.href = `/${fileName}`; // Redirect to the file
  }, [fileName]);
  return null; // Render nothing
};

function App() {
  return (
    <Router>
      <RootLayout>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <CookieNotice />
                <Banner />
                <Work />
                <Table />
                <Features />
                <Trade />
                <Simple />
                <TestimonialSlider />
              </>
            } 
          />

          <Route path="/category/:category_id" element={<Categories />} />
          <Route path="/article/:filename/:document_id" element={<DetailedArticle />} />
          <Route path="/contact-us" element={<ContactForm />} />
          <Route path="/about-us" element={<AboutUs />} />

          <Route path="/robots.txt" element={<RedirectToFile fileName="robots.txt" />} />
          <Route path="/ror.xml" element={<RedirectToFile fileName="ror.xml" />} />
          <Route path="/sitemap.xml" element={<RedirectToFile fileName="sitemap.xml" />} />
          <Route path="/sitemap.html" element={<RedirectToFile fileName="sitemap.xml" />} />
        </Routes>
      </RootLayout>
    </Router>
  );
}

export default App;
